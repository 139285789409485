import { css } from '@emotion/react';
import { colors } from '~/styles/colors';
import { mediaQueries } from '~/styles/mediaQueries';
import { textCss } from '~/styles/textCss';

export const container = css`
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 40px;
  border-radius: 8px;
`;

export const inputFields = css`
  display: flex;
  flex-flow: column;
  width: 100%;
  gap: 24px;
`;

export const loginButton = css`
  display: flex;
  flex-flow: column;
  width: 100%;
  gap: 24px;
  align-items: center;
`;

export const errorMessage = {
  container: css`
    ${textCss({ color: colors.alert, weight: 'bold', size: 'm' })}

    ${mediaQueries.mobile} {
      ${textCss({ size: 's' })}
    }

    line-height: 24px;
    display: flex;
    gap: 4px;
  `,
  icon: css`
    width: 18px;

    ${mediaQueries.mobile} {
      width: 14px;
    }
  `,
};
