import React from 'react';
import { LoginForm } from '~/categories/auth/components/domains/LoginForm';
import { AuthPageProps } from '~/categories/auth/components/pages/type';
import { useConfirmAuthenticated } from '~/categories/auth/hooks/login/useConfirmAuthenticated';
import { useLogin } from '~/categories/auth/hooks/login/useLogin';
import { globalPathBuilder } from '~/constants/globalPathBuilder';

export type Props = AuthPageProps;

export const LoginPage: React.FC<Props> = (props) => {
  const hook = useLogin(props.apiClient);
  useConfirmAuthenticated(props.apiClient, ({ authenticated }) => {
    if (authenticated) props.router.replace(globalPathBuilder.account);
  });

  return (
    <LoginForm
      onSubmit={(formValue) =>
        hook.actions.login(
          { email: formValue.email, password: formValue.password },
          {
            onSuccess: () => {
              props.router.replace(globalPathBuilder.account);
            },
          },
        )
      }
      sending={hook.state.sending}
      errorMessages={hook.state.errorMessages}
    />
  );
};
