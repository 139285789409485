import { useCallback, useState } from 'react';
import axios from 'axios';
import { IAuthApiClient } from '~/categories/auth/apis/authApiClient';
import { useErrorHandler } from '~/hooks/common/useErrorHandler';
import { useKarteLoginEvent } from '~/hooks/tracking/useKarteLoginEvent';
import { PostprocessCallback } from '~/types/callbacks';
import { messagesOfMessageError } from '~/utils/error/isErrorWithMessage';

type IUseLogin = (apiClient: IAuthApiClient) => {
  state: {
    sending: boolean;
    errorMessages?: string[];
  };
  actions: {
    login: (
      args: {
        email: string;
        password: string;
      },
      callbacks?: PostprocessCallback,
    ) => void;
  };
};

export const useLogin: IUseLogin = (apiClient) => {
  const [sending, setSending] = useState(false);
  const [errorMessages, setErrorMessages] = useState<string[]>();
  const { handleError } = useErrorHandler();
  const sendKarteLoginEvent = useKarteLoginEvent();

  const login = useCallback(
    async (
      args: {
        email: string;
        password: string;
      },
      callbacks?: PostprocessCallback,
    ) => {
      try {
        setErrorMessages(undefined);
        setSending(true);
        await apiClient.login(args);
        callbacks?.onSuccess?.();
        sendKarteLoginEvent(true);
      } catch (error) {
        if (axios.isAxiosError(error) && error.response?.status === 422) {
          // NOTE: メールとパスワードのセットが誤っている場合
          setErrorMessages(messagesOfMessageError(error));
        } else {
          handleError(error, callbacks?.onError);
        }
      } finally {
        setSending(false);
      }
    },
    [apiClient, handleError, sendKarteLoginEvent],
  );

  return {
    state: {
      sending,
      errorMessages,
    },
    actions: {
      login,
    },
  };
};
